<template>
    <div>
        <action-button
            icon="outbound-link"
            class="mx-3"
            @click="$emit('callback', item.id)">
            Manage
        </action-button>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';

export default {
    components: {
        ActionButton
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    }
};
</script>